import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';
import { XMarkIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';

function NoteDetail({ note, onClose, onDelete }) {
  const [activeTab, setActiveTab] = useState('note');

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return format(date, 'PPpp');
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to permanently delete this note?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'notes', note.id));
        toast.success('Note deleted successfully');
        onDelete(note.id); // Call the onDelete function passed from the parent
        onClose(); // Close the detail view
      } catch (error) {
        console.error('Error deleting note:', error);
        toast.error('Failed to delete note');
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative mx-auto p-5 border w-full h-full sm:h-auto sm:top-20 sm:max-w-2xl shadow-lg rounded-md bg-white overflow-y-auto">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <XMarkIcon className="h-6 w-6" />
        </button>
        
        {/* Centered top section */}
        <div className="text-center mb-6 pt-8 sm:pt-0">
          <p className="text-gray-500 text-sm mb-4">{formatDate(note.creationDate)}</p>
          <span className="text-5xl mb-4 block">{note.emoji}</span>
          <h2 className="text-2xl font-bold mb-4">{note.title}</h2>
          {note.tags && note.tags.length > 0 && (
            <div className="flex flex-wrap justify-center gap-2 mb-2">
              {note.tags.map((tag, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
                  {tag}
                </span>
              ))}
            </div>
          )}
        </div>

        <hr className="mb-6" />

        {/* Left-aligned content starts here */}
        <div className="text-left">
          {/* Keywords section */}
          {note.keywords && note.keywords.length > 0 && (
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Keywords:</h3>
              <div className="flex flex-wrap gap-2">
                {note.keywords.map((keyword, index) => (
                  <span key={index} className="bg-gray-200 text-gray-800 text-sm px-2 py-1 rounded">
                    {keyword}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Insights section */}
          {note.insights && (
            <div className="mb-4 bg-gray-100 rounded-lg p-4">
              <h3 className="text-lg font-semibold mb-2">Insights ✨</h3>
              <div className="prose max-w-none">
                <ReactMarkdown>{note.insights}</ReactMarkdown>
              </div>
            </div>
          )}

          {/* Summary section */}
          <div className="mb-4 max-w-none">
            <h3 className="text-sm2 font-bold text-gray-500 mb-4">SUMMARY</h3>
            {note.summary_short && note.summary_short.trim() !== '' && (
              <ReactMarkdown>{note.summary_short}</ReactMarkdown>
            )}
          </div>
          
          <div className="mb-4">
            <div className="flex border-b">
              <button
                className={`py-2 px-4 ${activeTab === 'note' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => setActiveTab('note')}
              >
                Note
              </button>
              <button
                className={`py-2 px-4 ${activeTab === 'transcript' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => setActiveTab('transcript')}
              >
                Transcript
              </button>
            </div>
            <div className="mt-4">
              {activeTab === 'note' && (
                <div className="prose max-w-none">
                  <ReactMarkdown>{note.summary}</ReactMarkdown>
                </div>
              )}
              {activeTab === 'transcript' && (
                <div className="prose max-w-none">
                  <ReactMarkdown>{note.transcript || 'No transcript available.'}</ReactMarkdown>
                </div>
              )}
            </div>
          </div>

          {/* Audio player */}
          {note.recordingURL && (
            <div className="mt-8">
              <h3 className="text-sm2 font-bold text-gray-500">RECORDING</h3>
              <div className="audio-player">
                <audio controls src={note.recordingURL} className="w-full">
                  Tu navegador no soporta el elemento de audio.
                </audio>
              </div>
            </div>
          )}

          {/* Delete button */}
          <div className="mt-12 pb-8 text-center">
            <button
              onClick={handleDelete}
              className="px-4 py-2 bg-gray-100 text-red-600 rounded hover:bg-gray-200 transition-colors"
            >
              Delete Note
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

NoteDetail.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    emoji: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    creationDate: PropTypes.object.isRequired,
    summary: PropTypes.string,
    summary_short: PropTypes.string,
    transcript: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    keywords: PropTypes.arrayOf(PropTypes.string),
    insights: PropTypes.string,
    recordingURL: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default NoteDetail;
