import React, { useState, useEffect, useRef, useCallback } from 'react';
import { XMarkIcon, TrashIcon, PauseIcon, PlayIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { MediaRecorder } from 'extendable-media-recorder';
import { MAX_RECORDING_DURATION } from '../constants';

function RecordingPanel({ onClose, onSend }) {
  const [isPaused, setIsPaused] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isRecording, setIsRecording] = useState(false);

  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const chunksRef = useRef([]);
  const timerIntervalRef = useRef(null);
  const durationRef = useRef(0);
  const isCancelledRef = useRef(false);

  const startTimer = useCallback(() => {
    console.log('startTimer called');
    if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
    timerIntervalRef.current = setInterval(() => {
      setDuration(prev => {
        const newDuration = prev + 1;
        console.log('Timer tick, new duration:', newDuration);
        durationRef.current = newDuration;
        return newDuration;
      });
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    console.log('stopTimer called');
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
  }, []);

  const handlePause = useCallback(() => {
    console.log('handlePause called');
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      stopTimer();
    }
  }, [stopTimer]);

  const handleResume = useCallback(() => {
    console.log('handleResume called');
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'paused') {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer();
    }
  }, [startTimer]);

  const handleSend = useCallback(() => {
    console.log('handleSend called, current duration:', durationRef.current);
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
  }, []);

  const handleCancel = useCallback(() => {
    console.log('handleCancel called');
    isCancelledRef.current = true;
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    chunksRef.current = [];
    stopTimer();
    setIsRecording(false);
    setIsPaused(false);
    setDuration(0);
    onClose(true); // Indicamos que esto es una cancelación
  }, [onClose, stopTimer]);

  const calculateProgress = (currentDuration) => {
    return Math.min((currentDuration / MAX_RECORDING_DURATION) * 100, 100);
  };

  useEffect(() => {
    console.log('useEffect for recorder initialization');
    const initRecorder = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            echoCancellation: true,
          }
        });
        streamRef.current = stream;

        const recorder = new MediaRecorder(stream, {
          mimeType: 'audio/wav'
        });
        mediaRecorderRef.current = recorder;

        recorder.ondataavailable = (event) => {
          if (!isCancelledRef.current) {
            chunksRef.current.push(event.data);
          }
        };

        recorder.onstart = () => {
          console.log('Recorder started');
          setIsRecording(true);
          setDuration(0);
          durationRef.current = 0;
          startTimer();
        };

        recorder.onpause = () => {
          console.log('Recorder paused');
          stopTimer();
        };

        recorder.onresume = () => {
          console.log('Recorder resumed');
          startTimer();
        };

        recorder.onstop = () => {
          console.log('Recorder stopped, final duration:', durationRef.current);
          stopTimer();
          setIsRecording(false);
          if (!isCancelledRef.current) {
            const audioBlob = new Blob(chunksRef.current, { type: 'audio/wav' });
            console.log('Audio blob created, size:', audioBlob.size);
            if (durationRef.current >= 5) {
              console.log('Sending audio, duration:', durationRef.current);
              onSend(audioBlob, durationRef.current);
            } else {
              console.log('Audio too short, duration:', durationRef.current);
              alert('La grabación debe durar al menos 5 segundos para enviarla.');
            }
            chunksRef.current = [];
            onClose(false);
          }
        };

        recorder.start();
        console.log('Recorder initialized and started');
      } catch (error) {
        console.error('Error initializing recorder:', error);
        alert('No se pudo inicializar el grabador de audio. ' + error.message);
        onClose(true);
      }
    };

    initRecorder();

    return () => {
      handleCancel();
    };
  }, [onClose, onSend, startTimer, stopTimer, handleCancel]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${mins}:${secs}/${Math.floor(MAX_RECORDING_DURATION / 60).toString().padStart(2, '0')}:00`;
  };

  console.log('Render: duration =', duration, 'isPaused =', isPaused, 'isRecording =', isRecording);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-80">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Grabando Nota de Voz</h2>
          <button onClick={handleCancel} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="text-center mb-4">
          <p className="text-gray-700 mb-2">{formatTime(duration)}</p>
          <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 my-4">
            <div 
              className="bg-orange-500 h-1.5 rounded-full transition-all duration-300 ease-in-out" 
              style={{ width: `${calculateProgress(duration)}%` }}
            ></div>
          </div>
        </div>
        <div className="flex justify-around">
          <button
            onClick={handleCancel}
            className="p-2 bg-gray-200 hover:bg-gray-300 rounded-full"
            title="Cancelar Grabación"
          >
            <TrashIcon className="w-6 h-6 text-red-500" />
          </button>
          {isPaused ? (
            <button
              onClick={handleResume}
              className="p-2 bg-gray-200 hover:bg-gray-300 rounded-full"
              title="Reanudar Grabación"
            >
              <PlayIcon className="w-6 h-6 text-blue-500" />
            </button>
          ) : (
            <button
              onClick={handlePause}
              className="p-2 bg-gray-200 hover:bg-gray-300 rounded-full"
              title="Pausar Grabación"
            >
              <PauseIcon className="w-6 h-6 text-yellow-500" />
            </button>
          )}
          <button
            onClick={handleSend}
            disabled={duration < 5 || !isRecording}
            className={`p-2 bg-gray-200 hover:bg-gray-300 rounded-full ${(duration < 5 || !isRecording) ? 'opacity-50 cursor-not-allowed' : ''}`}
            title="Enviar Grabación"
          >
            <PaperAirplaneIcon className="w-6 h-6 text-green-500 transform rotate-45" />
          </button>
        </div>
      </div>
    </div>
  );
}

RecordingPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default RecordingPanel;